import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Row, Col, Card } from "reactstrap"
import Email from "../components/Email"
import { graphql, StaticQuery } from "gatsby"

const Ueberuns = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            shorttitle
            phone
            phonelink
            fax
            street
            city
            zipcode
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Impressum" />
        <Row>
          <Col>
            <Card className="bg-transparent">
              <div className="card-body" id="top">
                <h2 className="card-title">Impressum</h2>
                <hr />
                <ul className="list-unstyled">
                  <li>
                    <a href="#verantw">Verantwortlich für den Inhalt</a>
                  </li>
                  <li>
                    <a href="#webdesign">Web-Layout, Design und Realisierung</a>
                  </li>
                  <li>
                    <a href="#pflicht">Pflichtangaben</a>
                  </li>
                  <li>
                    <a href="#rechtshinweise">Rechtshinweise</a>
                    <ul className="list-unstyled pl-3">
                      <li>
                        <a href="#haftunginhalte">Haftung für Inhalte</a>
                      </li>
                      <li>
                        <a href="#haftunglinks">Haftung für Links</a>
                      </li>
                      <li>
                        <a href="#urheberrecht">Urheberrecht</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#datenschutz">Datenschutzerklärung</a>
                    <ul className="list-unstyled pl-3">
                      <li>
                        <a href="#ds1">
                          1. Name und Kontaktdaten des Verantwortlichen
                        </a>
                      </li>
                      <li>
                        <a href="#ds2">
                          2. Umfang und Zweck der Verarbeitung personenbezogener
                          Daten
                        </a>
                        <ul className="list-unstyled pl-3">
                          <li>
                            <a href="#ds21">2.1 Aufruf der Webseite</a>
                          </li>
                          <li>
                            <a href="#ds22">2.2 Externe Dienste</a>
                          </li>
                          <li>
                            <a href="#ds23">2.3 Cookies</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#ds3">3. Weitergabe von Daten</a>
                      </li>
                      <li>
                        <a href="#ds4">4. Ihre Rechte als betroffene Person</a>
                      </li>
                      <ul className="list-unstyled pl-3">
                        <li>
                          <a href="#ds41">4.1 Auskunft</a>
                        </li>
                        <li>
                          <a href="#ds42">
                            4.2 Berichtigung und Vervollständigung
                          </a>
                        </li>
                        <li>
                          <a href="#ds43">4.3 Löschung</a>
                        </li>
                        <li>
                          <a href="#ds44">4.4 Einschränkung der Verarbeitung</a>
                        </li>
                        <li>
                          <a href="#ds45">4.5 Datenübertragbarkeit</a>
                        </li>
                        <li>
                          <a href="#ds46">4.6 Widerspruch</a>
                        </li>
                        <li>
                          <a href="#ds47">4.7 Widerruf einer Einwilligung</a>
                        </li>
                        <li>
                          <a href="#ds48">4.8 Beschwerde</a>
                        </li>
                      </ul>
                      <li>
                        <a href="#ds5">
                          5. Stand und Aktualisierung dieser
                          Datenschutzerklärung
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

                <h4 className="pt-4" id="verantw">
                  Verantwortlich für den Inhalt
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h4>
                <p>
                  Dipl.-Finanzwirt Hans Peter Waerder <br />
                  Dipl.-Ökonom Jochen Lorenz <br />
                  Steuerberater <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.site.siteMetadata.street,
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.site.siteMetadata.zipcode,
                    }}
                  />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.site.siteMetadata.city,
                    }}
                  />
                  <br />
                  Telefon:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.site.siteMetadata.phone,
                    }}
                  />
                  <br />
                  Telefax:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data.site.siteMetadata.fax,
                    }}
                  />
                  <br />
                  Web:{" "}
                  <a href="http://www.waerder-lorenz.de">
                    www.waerder-lorenz.de
                  </a>
                  <br />
                  E-Mail: <Email />
                </p>
                <h4 className="pt-4" id="webdesign">
                  Web-Layout, Design und Realisierung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h4>
                <p>
                  Sascha Boch Softwareentwicklung
                  <br />
                  <a
                    href="http://www.codagil.de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.codagil.de
                  </a>
                </p>
                <h4 id="pflicht" className="pt-4">
                  Pflichtangaben
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h4>
                <div>
                  Gemäß des Gesetzes über die rechtlichen Rahmenbedingungen für
                  den elektronischen Geschäftsverkehr vom 20. Dezember 2001
                  (verkündet im BGBl 2001 Teil I Nr. 70, Seite 3721/
                  <a
                    href="http://www.bgbl.de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.bgbl.de
                  </a>
                  ) sind wir verpflichtet, folgende Angaben zu machen:
                  <ul className="pl-4">
                    <li>
                      Zuständige Aufsichtsbehörde ist die Steuerberaterkammer
                      Köln.
                    </li>
                    <li>
                      Die gesetzliche Berufsbezeichnung
                      &bdquo;Steuerberater&ldquo; wurde in der Bundesrepublik
                      Deutschland verliehen.
                    </li>
                    <li>
                      Die Steuerberater unterliegen im wesentlichen den
                      nachstehenden berufsrechtlichen Regelungen:
                      <ul>
                        <li>StBerG</li>
                        <li>Durchführungsverordnungen zum StBerG</li>
                        <li>Berufsordnung</li>
                        <li>Steuerberatergebührenverordnung</li>
                      </ul>
                      Die berufsrechtlichen Regelungen können bei der
                      Bundessteuerberaterkammer Berlin eingesehen werden (
                      <a
                        href="http://www.bstbk.de"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.bstbk.de
                      </a>
                      ).
                    </li>
                  </ul>
                </div>
                <h4 id="rechtshinweise">Rechtshinweise</h4>
                <h5 id="haftunginhalte">
                  Haftung für Inhalte
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h5>
                <p>
                  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                  Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                  verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                  Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                  gespeicherte fremde Informationen zu überwachen oder nach
                  Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                  hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                  Nutzung von Informationen nach den allgemeinen Gesetzen
                  bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                  jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                  Rechtsverletzungen werden wir diese Inhalte umgehend
                  entfernen.
                </p>
                <h5 id="haftunglinks">
                  Haftung für Links
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h5>
                <p>
                  Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                  deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                  für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                  die Inhalte der verlinkten Seiten ist stets der jeweilige
                  Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                  mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                  zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                  inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                  Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                  Links umgehend entfernen.
                </p>
                <h5 id="urheberrecht">
                  Urheberrecht
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h5>
                <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                  diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                  Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                  Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
                  der schriftlichen Zustimmung des jeweiligen Autors bzw.
                  Erstellers. Downloads und Kopien dieser Seite sind nur für den
                  privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
                  Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                  werden die Urheberrechte Dritter beachtet. Insbesondere werden
                  Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                  trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                  bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                  von Rechtsverletzungen werden wir derartige Inhalte umgehend
                  entfernen.
                </p>
                <h5 id="datenschutz">Datenschutzerklärung</h5>
                <h6 id="ds1">
                  1. Name und Kontaktdaten des Verantwortlichen
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Diese Datenschutzerklärung informiert über die Verarbeitung
                  personenbezogener Daten auf der Kanzleiwebseite von:
                </p>
                <p>
                  Dipl.-Finanzwirt Hans Peter Waerder <br />
                  Dipl.-Ökonom Jochen Lorenz <br />
                  Steuerberater <br />
                  <br />
                  Kölner Str. 56, D-51399 Burscheid <br />
                  Telefon: (02174) 78392-0 <br />
                  Telefax: (02174) 78392-29{" "}
                </p>
                <h6 id="ds2">
                  2. Umfang und Zweck der Verarbeitung personenbezogener Daten
                </h6>
                <h6 id="ds21">
                  2.1 Aufruf der Webseite
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Beim Aufruf dieser Webseite www.waerder-lorenz.de werden
                    durch den Internet-Browser, den der Besucher verwendet,
                    automatisch Daten an den Server dieser Webseite gesendet und
                    zeitlich begrenzt in einer Protokolldatei (Logfile)
                    gespeichert. Bis zur automatischen Löschung werden
                    nachstehende Daten ohne weitere Eingabe des Besuchers
                    gespeichert:
                  </p>
                  <ul>
                    <li>IP-Adresse des Endgeräts des Besuchers,</li>
                    <li>Datum und Uhrzeit des Zugriffs durch den Besucher,.</li>
                    <li>Name und URL der vom Besucher aufgerufenen Seite,</li>
                    <li>
                      Webseite, von der aus der Besucher auf die Kanzleiwebseite
                      gelangt (sog. Referrer-URL),
                    </li>
                    <li>
                      Browser und Betriebssystem des Endgeräts des Besuchers
                      sowie der Name des vom Besucher verwendeten
                      Access-Providers.
                    </li>
                  </ul>
                </div>
                <div>
                  <p>
                    Die Verarbeitung dieser personenbezogenen Daten ist gem.
                    Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Die
                    Kanzlei hat ein berechtigtes Interesse an der
                    Datenverarbeitung zu dem Zweck,
                  </p>
                  <ul>
                    <li>
                      die Verbindung zur Webseite der Kanzlei zügig aufzubauen,
                    </li>
                    <li>
                      eine nutzerfreundliche Anwendung der Webseite zu
                      ermöglichen,
                    </li>
                    <li>
                      die Sicherheit und Stabilität der Systeme zu erkennen und
                      zu gewährleisten und
                    </li>
                    <li>
                      die Administration der Webseite zu erleichtern und zu
                      verbessern.
                    </li>
                  </ul>
                  <p>
                    Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck,
                    Erkenntnisse über die Person des Besuchers der Webseite zu
                    gewinnen.
                  </p>
                </div>
                <h6 id="ds22">
                  2.2 Externe Dienste
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Diese Webseite bindet das Produkt Google Maps des
                    Unternehmens Google LLC (im folgenden kurz
                    &bdquo;Google&ldquo;), Amphitheatre Parkway, Mountain View,
                    CA 94043, USA, ein, um dem Nutzer die Möglichkeit zu geben,
                    sich über unseren Standort und die Anreisemöglichkeiten zu
                    informieren.
                  </p>
                  <p>
                    Durch die Nutzung unserer Webseite erklären Sie sich damit
                    einverstanden, dass Google, deren Partner, Vertreter
                    und/oder dritte Parteien, welche die erfassten Daten durch
                    Google erhalten, diese Daten erfasst, verarbeitet und nutzt.
                    Detaillierte Informationen dazu finden Sie in den{" "}
                    <a
                      href="https://policies.google.com/terms?gl=US&hl=de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      allgemeinen Nutzungsbedingungen von Google
                    </a>{" "}
                    und in den{" "}
                    <a
                      href="https://www.google.com/intl/de_de/help/terms_maps/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      zusätzlichen Nutzungsbedingungen von Google Maps
                    </a>{" "}
                    sowie in der{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung von Google
                    </a>
                    . Wir haben als Betreiber dieser Webseite keinen Einfluß auf
                    die Übertragung Ihrer Daten an Google.
                  </p>
                </div>
                <h6 id="ds23">
                  2.3 Cookies
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Diese Webseite verwendet einen sogenannten{" "}
                    <a href="https://de.wikipedia.org/wiki/HTTP-Cookie">
                      Cookie
                    </a>{" "}
                    damit festgestellt werden kann, ob Sie sich bereits mit den
                    Nutzungsbedingungen und der Datenschutzerklärung dieser
                    Webseite einverstanden erklärt haben. Dieser Cookie wird
                    gesetzt, wenn Sie Ihr Einverständnis durch Klicken auf die
                    dafür vorgesehene Schaltfläche in dem angezeigten Hinweis
                    erklärt haben. Bei weiteren Besuchen dieser Webseite wird
                    der Hinweis entsprechend nicht mehr angezeigt, da auf Grund
                    des gesetzten Cookies erkannt werden kann, dass Ihr
                    Einverständnis bereits erklärt wurde. Bitte beachten Sie,
                    dass dies nur bei Benutzung der gleichen Browser-Software
                    funktioniert; sollten Sie bei einem nachfolgenden Besuch ein
                    anderes Endgerät oder eine andere Browser-Software nutzen
                    oder den Cookie gelöscht haben, wird der Hinweis wieder
                    angezeigt.
                  </p>
                  <p>
                    Der Cookie dient lediglich dem Zweck, die Benutzung dieser
                    Webseite angenehm zu gestalten. Ihr Nutzungsverhalten dieser
                    oder anderer Webseiten wird dadurch nicht analysiert.
                  </p>
                </div>
                <h6 id="ds3">
                  3. Weitergabe von Daten
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>Personenbezogene Daten werden an Dritte übermittelt, wenn</p>
                <ul>
                  <li>
                    nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die
                    betroffene Person ausdrücklich dazu eingewilligt wurde,
                  </li>
                  <li>
                    die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO
                    zur Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                    besteht, dass die betroffene Person ein überwiegendes
                    schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten
                    hat,
                  </li>
                  <li>
                    für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst.
                    c) DSGVO eine gesetzliche Verpflichtung besteht, und/oder
                  </li>
                  <li>
                    dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die
                    Erfüllung eines Vertragsverhältnisses mit der betroffenen
                    Person erforderlich ist.
                  </li>
                </ul>
                <p>
                  In anderen Fällen werden personenbezogene Daten nicht an
                  Dritte weitergegeben.
                </p>
                <h6 id="ds4">
                  4. Ihre Rechte als betroffene Person
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Soweit Ihre personenbezogenen Daten anlässlich des Besuchs
                  unserer Webseite verarbeitet werden, stehen Ihnen als
                  „betroffene Person“ im Sinne der DSGVO folgende Rechte zu:
                </p>
                <h6 id="ds41">
                  4.1 Auskunft
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Sie können von uns Auskunft darüber verlangen, ob
                    personenbezogene Daten von Ihnen bei uns verarbeitet werden.
                    Kein Auskunftsrecht besteht, wenn die Erteilung der
                    begehrten Informationen gegen die Verschwiegenheitspflicht
                    gem. § 83 StBerG verstoßen würde oder die Informationen aus
                    sonstigen Gründen, insbesondere wegen eines überwiegenden
                    berechtigten Interesses eines Dritten, geheim gehalten
                    werden müssen. Hiervon abweichend kann eine Pflicht zur
                    Erteilung der Auskunft bestehen, wenn insbesondere unter
                    Berücksichtigung drohender Schäden Ihre Interessen gegenüber
                    dem Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht
                    ist ferner ausgeschlossen, wenn die Daten nur deshalb
                    gespeichert sind, weil sie aufgrund gesetzlicher oder
                    satzungsmäßiger Aufbewahrungsfristen nicht gelöscht werden
                    dürfen oder ausschließlich Zwecken der Datensicherung oder
                    der Datenschutzkontrolle dienen, sofern die
                    Auskunftserteilung einen unverhältnismäßig hohen Aufwand
                    erfordern würde und die Verarbeitung zu anderen Zwecken
                    durch geeignete technische und organisatorische Maßnahmen
                    ausgeschlossen ist. Sofern in Ihrem Fall das Auskunftsrecht
                    nicht ausgeschlossen ist und Ihre personenbezogenen Daten
                    von uns verarbeitet werden, können Sie von uns Auskunft über
                    folgende Informationen verlangen:
                  </p>
                  <ul>
                    <li>Zwecke der Verarbeitung,</li>
                    <li>
                      Kategorien der von Ihnen verarbeiteten personenbezogenen
                      Daten,
                    </li>
                    <li>
                      Empfänger oder Kategorien von Empfängern, gegenüber denen
                      Ihre personenbezogenen Daten offen gelegt werden,
                      insbesondere bei Empfängern in Drittländern,
                    </li>
                    <li>
                      falls möglich die geplante Dauer, für die Ihre
                      personenbezogenen Daten gespeichert werden oder, falls
                      dies nicht möglich ist, die Kriterien für die Festlegung
                      der Speicherdauer,
                    </li>
                    <li>
                      das Bestehen eines Rechts auf Berichtigung oder Löschung
                      oder Einschränkung der Verarbeitung der Sie betreffenden
                      personenbezogenen Daten oder eines Widerspruchsrechts
                      gegen diese Verarbeitung,
                    </li>
                    <li>
                      das Bestehen eines Beschwerderechts bei einer
                      Aufsichtsbehörde für den Datenschutz,
                    </li>
                    <li>
                      sofern die personenbezogenen Daten nicht bei Ihnen als
                      betroffene Person erhoben worden sind, die verfügbaren
                      Informationen über die Datenherkunft,
                    </li>
                    <li>
                      ggf. das Bestehen einer automatisierten
                      Entscheidungsfindung einschließlich Profiling und
                      aussagekräftige Informationen über die involvierte Logik
                      sowie die Tragweite und angestrebten Auswirkungen
                      automatisierter Entscheidungsfindungen,
                    </li>
                    <li>
                      ggf. im Fall der Übermittlung an Empfänger in
                      Drittländern, sofern kein Beschluss der EU-Kommission über
                      die Angemessenheit des Schutzniveaus nach Art. 45 Abs. 3
                      DSGVO vorliegt, Informationen darüber, welche geeigneten
                      Garantien gem. Art. 46 Abs. 2 DSGVO zum Schutze der
                      personenbezogenen Daten vorgesehen sind.
                    </li>
                  </ul>
                </div>
                <h6 id="ds42">
                  4.2 Berichtigung und Vervollständigung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Sofern Sie feststellen, dass uns unrichtige personenbezogene
                  Daten von Ihnen vorliegen, können Sie von uns die
                  unverzügliche Berichtigung dieser unrichtigen Daten verlangen.
                  Bei unvollständigen Sie betreffenden personenbezogenen Daten
                  können sie die Vervollständigung verlangen.
                </p>
                <h6 id="ds43">
                  4.3 Löschung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Sie haben ein Recht auf Löschung („Recht auf
                    Vergessenwerden“), sofern die Verarbeitung nicht zur
                    Ausübung des Rechts auf freie Meinungsäußerung, des Rechts
                    auf Information oder zur Erfüllung einer rechtlichen
                    Verpflichtung oder zur Wahrnehmung einer Aufgabe, die im
                    öffentlichen Interesse liegt, erforderlich ist und einer der
                    nachstehenden Gründe zutrifft:
                  </p>
                  <ul>
                    <li>
                      Die personenbezogenen Daten sind für die Zwecke, für die
                      sie verarbeitet wurden, nicht mehr notwendig.
                    </li>
                    <li>
                      Die Rechtfertigungsgrundlage für die Verarbeitung war
                      ausschließlich Ihre Einwilligung, welche Sie widerrufen
                      haben.
                    </li>
                    <li>
                      Sie haben Widerspruch gegen die Verarbeitung Ihrer
                      personenbezogenen Daten eingelegt, die wir öffentlich
                      gemacht haben.
                    </li>
                    <li>
                      Sie haben Widerspruch gegen die Verarbeitung von uns nicht
                      öffentlich gemachter personenbezogener Daten eingelegt und
                      es liegen keine vorrangigen berechtigten Gründe für die
                      Verarbeitung vor.
                    </li>
                    <li>
                      Ihre personenbezogenen Daten wurden unrechtmäßig
                      verarbeitet.
                    </li>
                    <li>
                      Die Löschung der personenbezogenen Daten ist zur Erfüllung
                      einer gesetzlichen Verpflichtung, der wir unterliegen,
                      erforderlich.
                    </li>
                  </ul>
                  <p>
                    Kein Anspruch auf Löschung besteht, wenn die Löschung im
                    Falle rechtmäßiger nicht automatisierter Datenverarbeitung
                    wegen der besonderen Art der Speicherung nicht oder nur mit
                    unverhältnismäßig hohem Aufwand möglich und Ihr Interesse an
                    der Löschung gering ist. In diesem Fall tritt an die Stelle
                    einer Löschung die Einschränkung der Verarbeitung.
                  </p>
                </div>
                <h6 id="ds44">
                  4.4 Einschränkung der Verarbeitung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <div>
                  <p>
                    Sie können von uns die Einschränkung der Verarbeitung
                    verlangen, wenn einer der nachstehenden Gründe zutrifft:
                  </p>
                  <ul>
                    <li>
                      Sie bestreiten die Richtigkeit der personenbezogenen
                      Daten. Die Einschränkung kann in diesem Fall für die Dauer
                      verlangt werden, die es uns ermöglicht, die Richtigkeit
                      der Daten zu überprüfen.
                    </li>
                    <li>
                      Die Verarbeitung ist unrechtmäßig und Sie verlangen statt
                      Löschung die Einschränkung der Nutzung Ihrer
                      personenbezogenen Daten.
                    </li>
                    <li>
                      Ihre personenbezogenen Daten werden von uns nicht länger
                      für die Zwecke der Verarbeitung benötigt, die Sie jedoch
                      zur Geltendmachung, Ausübung oder Verteidigung von
                      Rechtsansprüchen benötigen.
                    </li>
                    <li>
                      Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt.
                      Die Einschränkung der Verarbeitung kann solange verlangt
                      werden, wie noch nicht feststeht, ob unsere berechtigten
                      Gründe gegenüber Ihren Gründen überwiegen.
                    </li>
                  </ul>
                  <p>
                    Einschränkung der Verarbeitung bedeutet, dass die
                    personenbezogenen Daten nur mit Ihrer Einwilligung oder zur
                    Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                    natürlichen oder juristischen Person oder aus Gründen eines
                    wichtigen öffentlichen Interesses verarbeitet werden. Bevor
                    wir die Einschränkung aufheben, haben wir die Pflicht, Sie
                    darüber zu unterrichten.
                  </p>
                </div>
                <h6 id="ds45">
                  4.5 Datenübertragbarkeit
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Sie haben ein Recht auf Datenübertragbarkeit, sofern die
                  Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1
                  Buchst. a) oder Art. 9 Abs. 2 Buchst. a) DSGVO) oder auf einem
                  Vertrag beruht, dessen Vertragspartei Sie sind und die
                  Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das
                  Recht auf Datenübertragbarkeit beinhaltet in diesem Fall
                  folgende Rechte, sofern hierdurch nicht die Rechte und
                  Freiheiten anderer Personen beeinträchtigt werden: Sie können
                  von uns verlangen, die personenbezogenen Daten, die Sie uns
                  bereit gestellt haben, in einem strukturierten, gängigen und
                  maschinenlesbaren Format zu erhalten. Sie haben das Recht,
                  diese Daten einem anderen Verantwortlichen ohne Behinderung
                  unserseits zu übermitteln. Soweit technisch machbar, können
                  Sie von uns verlangen, dass wir Ihre personenbezogenen Daten
                  direkt an einen anderen Verantwortlichen übermitteln.
                </p>
                <h6 id="ds46">
                  4.6 Widerspruch
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e)
                  DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse
                  oder in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1
                  Satz 1 Buchst. f) DSGVO (berechtigtes Interesse des
                  Verantwortlichen oder eines Dritten) beruht, haben Sie das
                  Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                  ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                  personenbezogenen Daten Widerspruch einzulegen. Das gilt auch
                  für ein auf Art. 6 Abs. 1 Satz 1 Buchst. e) oder Buchst. f)
                  DSGVO gestütztes Profiling. Nach Ausübung des
                  Widerspruchsrechts verarbeiten wir Ihre personenbezogenen
                  Daten nicht mehr, es sei denn, wir können zwingende
                  schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                  Interessen, Rechte und Freiheiten überwiegen, oder die
                  Verarbeitung dient der Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen.
                </p>
                <p>
                  Sie können jederzeit Widerspruch gegen die Verarbeitung der
                  Sie betreffenden personenbezogenen Daten zu Zwecken der
                  Direktwerbung einlegen. Das gilt auch für ein Profiling, das
                  mit einer solchen Direktwerbung in Verbindung steht. Nach
                  Ausübung dieses Widerspruchsrechts werden wir die betreffenden
                  personenbezogenen Daten nicht mehr für Zwecke der
                  Direktwerbung verwenden.
                </p>
                <p>
                  Sie haben die Möglichkeit, den Widerspruch telefonisch, per
                  E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser
                  Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei
                  formlos mitzuteilen.
                </p>
                <h6 id="ds47">
                  4.7 Widerruf einer Einwilligung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Sie haben das Recht, eine erteilte Einwilligung jederzeit mit
                  Wirkung für die Zukunft zu widerrufen. Der Widerruf der
                  Einwilligung kann telefonisch, per E-Mail, ggf. per Telefax
                  oder an unsere Postadresse formlos mitgeteilt werden. Durch
                  den Widerruf wird die Rechtmäßigkeit der Datenverarbeitung,
                  die aufgrund der Einwilligung bis zum Eingang des Widerrufs
                  erfolgt ist, nicht berührt. Nach Eingang des Widerrufs wird
                  die Datenverarbeitung, die ausschließlich auf Ihrer
                  Einwilligung beruhte, eingestellt.
                </p>
                <h6 id="ds48">
                  4.8 Beschwerde
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                  betreffenden personenbezogenen Daten rechtswidrig ist, können
                  Sie Beschwerde bei einer Aufsichtsbehörde für den Datenschutz
                  einlegen, die für den Ort Ihres Aufenthaltes oder
                  Arbeitsplatzes oder für den Ort des mutmaßlichen Verstoßes
                  zuständig ist.
                </p>
                <h6 id="ds5">
                  5. Stand und Aktualisierung dieser Datenschutzerklärung
                  <a
                    className="verysmall nav-link float-right pr-0"
                    href="#top"
                  >
                    Zurück nach oben
                  </a>
                </h6>
                <p>
                  Diese Datenschutzerklärung hat den Stand vom 25. Mai 2018. Wir
                  behalten uns vor, die Datenschutzerklärung zu gegebener Zeit
                  zu aktualisieren, um den Datenschutz zu verbessern und/oder an
                  geänderte Behördenpraxis oder Rechtsprechung anzupassen.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    )}
  />
)

export default Ueberuns
